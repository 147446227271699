

  .twoColumns {
    columns: 2 auto;
  }

  .threeColumns {
    columns: 3 auto;
  }
  .fourColumns {
    columns: 4 auto;
  }